export const REPEATED_NUMBERS_REGEX = /([0-9])\1{10}/g;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const CPF_MASK = "###.###.###-##";
export const CNPJ_MASK = "##.###.###/####-##";
export const BRAZIL_ZIP_CODE_MASK = "#####-###";
export const GENERIC_ZIP_CODE_MASK = "###########";
export const BRAZIL_PHONE_NUMBER_MASK = "(##) ####-####";
export const BRAZIL_9TH_DIGIT_PHONE_NUMBER_MASK = "(##) #####-####";

export const MEXICAN_CPF_MASK = "SSSS######XXXXXXXX"; //may it's wrong?

export const STATES = {
  AR_STATES: {
    "BA - Buenos Aires": "BA",
    "CT - Catamarca": "CT",
    "CH - Chaco": "CH",
    "CB - Chubut": "CB",
    "CD - Córdoba": "CD",
    "CR - Corrientes": "CR",
    "ER - Entre Ríos": "ER",
    "FO - Formosa": "FO",
    "JY - Jujuy": "JY",
    "LP - La Pampa": "LP",
    "LR - La Rioja": "LR",
    "MZ - Mendoza": "MZ",
    "MN - Misiones": "MN",
    "NQ - Neuquén": "NQ",
    "RN - Río Negro": "RN",
    "SA - Salta": "SA",
    "SJ - San Juan": "SJ",
    "SL - San Luis": "SL",
    "SC - Santa Cruz": "SC",
    "SF - Santa Fe": "SF",
    "SE - Santiago del Estero": "SE",
    "TF - Tierra del Fuego, Antártida e Islas del Atlántico Sur": "TF",
    "TU - Tucumán": "TU",
  },
  BR_STATES: {
    "AC - Acre": "AC",
    "AL - Alagoas": "AL",
    "AP - Amapá": "AP",
    "AM - Amazonas": "AM",
    "BA - Bahia": "BA",
    "CE - Ceará": "CE",
    "DF - Distrito Federal": "DF",
    "ES - Espírito Santo": "ES",
    "GO - Goiás": "GO",
    "MA - Maranhão": "MA",
    "MT - Mato Grosso": "MT",
    "MS - Mato Grosso do Sul": "MS",
    "MG - Minas Gerais": "MG",
    "PA - Pará": "PA",
    "PB - Paraíba": "PB",
    "PR - Paraná": "PR",
    "PE - Pernambuco": "PE",
    "PI - Piauí": "PI",
    "RJ - Rio de Janeiro": "RJ",
    "RN - Rio Grande do Norte": "RN",
    "RS - Rio Grande do Sul": "RS",
    "RO - Rondônia": "RO",
    "RR - Roraima": "RR",
    "SC - Santa Catarina": "SC",
    "SP - São Paulo": "SP",
    "SE - Sergipe": "SE",
    "TO - Tocantins": "TO",
  },
  CL_STATES: {
    "AI - Aisén del General Carlos Ibáñez del Campo": "AI",
    "AN - Antofagasta": "AN",
    "AR - Araucanía": "AR",
    "AT - Atacama": "AT",
    "BI - Bío-Bío": "BI",
    "CO - Coquimbo": "CO",
    "LI - Libertador General Bernardo O'Higgins'": "LI",
    "LL - Los Lagos": "LL",
    "LR - Los Ríos": "LR",
    "MA - Magallanes y de la Antártica Chilena": "MA",
    "ML - Maule": "ML",
    "RM - Región Metropolitana de Santiago": "RM",
    "TA - Tarapacá": "TA",
    "VS - Valparaíso": "VS",
  },
  CO_STATES: {
    "AMA - Amazonas": "AMA",
    "ANT - Antioquia": "ANT",
    "ARA - Arauca": "ARA",
    "ATL - Atlántico": "ATL",
    "BOL - Bolívar": "BOL",
    "BOY - Boyacá": "BOY",
    "CAL - Caldas": "CAL",
    "CAQ - Caquetá": "CAQ",
    "CAS - Casanare": "CAS",
    "CAU - Cauca": "CAU",
    "CES - Cesar": "CES",
    "CHO - Chocó": "CHO",
    "CÓR - Córdoba": "CÓR",
    "CUN - Cundinamarca": "CUN",
    "GUA - Guainía": "GUA",
    "GUV - Guaviare": "GUV",
    "HUI - Huila": "HUI",
    "LAG - La Guajira": "LAG",
    "MAG - Magdalena": "MAG",
    "MET - Meta": "MET",
    "NAR - Nariño": "NAR",
    "NOR - Norte de Santander": "NOR",
    "PUT - Putumayo": "PUT",
    "QUI - Quindío": "QUI",
    "RIS - Risaralda": "RIS",
    "SAN - San Andrés y Providencia": "SAN",
    "SAN - Santander": "SAN",
    "SUC - Sucre": "SUC",
    "TOL - Tolima": "TOL",
    "VAC - Valle del Cauca": "VAC",
    "VAU - Vaupés": "VAU",
    "VID - Vichada": "VID",
  },
  EC_STATES: {
    "AZU - Azuay": "AZU",
    "BOL - Bolívar": "BOL",
    "CAN - Cañar": "CAN",
    "CAR - Carchi": "CAR",
    "CHI - Chimborazo": "CHI",
    "COT - Cotopaxi": "COT",
    "IMO - El Oro": "IMO",
    "ESM - Esmeraldas": "ESM",
    "GAL - Galápagos": "GAL",
    "GUA - Guayas": "GUA",
    "HUA - Imbabura": "HUA",
    "LOJ - Loja": "LOJ",
    "LRO - Los Ríos": "LRO",
    "MAN - Manabí": "MAN",
    "MSA - Morona Santiago": "MSA",
    "NAP - Napo": "NAP",
    "ORE - Orellana": "ORE",
    "PAS - Pastaza": "PAS",
    "PIC - Pichincha": "PIC",
    "SDO - Santo Domingo de los Tsáchilas": "SDO",
    "SUC - Sucumbíos": "SUC",
    "TUN - Tungurahua": "TUN",
    "ZCH - Zamora-Chinchipe": "ZCH",
  },
  MX_STATES: {
    "AGS - Aguascalientes": "AGS",
    "BCN - Baja California": "BCN",
    "BCS - Baja California Sur": "BCS",
    "CAM - Campeche": "CAM",
    "CHS - Chiapas": "CHS",
    "CHI - Chihuahua": "CHI",
    "CDMX - Ciudad de México": "CDMX",
    "COA - Coahuila": "COA",
    "COL - Colima": "COL",
    "DGO - Durango": "DGO",
    "EM - Estado de México": "EM",
    "GTO - Guanajuato": "GTO",
    "GRO - Guerrero": "GRO",
    "HGO - Hidalgo": "HGO",
    "JAL - Jalisco": "JAL",
    "MICH - Michoacán": "MICH",
    "MOR - Morelos": "MOR",
    "NAY - Nayarit": "NAY",
    "NL - Nuevo León": "NL",
    "OAX - Oaxaca": "OAX",
    "PUE - Puebla": "PUE",
    "QRO - Querétaro": "QRO",
    "QR - Quintana Roo": "QR",
    "SLP - San Luis Potosí": "SLP",
    "SIN - Sinaloa": "SIN",
    "SON - Sonora": "SON",
    "TAB - Tabasco": "TAB",
    "TAM - Tamaulipas": "TAM",
    "TLAX - Tlaxcala": "TLAX",
    "VER - Veracruz": "VER",
    "YUC - Yucatán": "YUC",
    "ZAC - Zacatecas": "ZAC",
  },
  PE_STATES: {
    "AM - Amazonas": "AM",
    "AN - Áncash": "AN",
    "AP - Apurímac": "AP",
    "AR - Arequipa": "AR",
    "AY - Ayacucho": "AY",
    "CA - Cajamarca": "CA",
    "CU - Cusco": "CU",
    "HV - Huancavelica": "HV",
    "HO - Huánuco": "HO",
    "IC - Ica": "IC",
    "JU - Junín": "JU",
    "LC - La Libertad": "LC",
    "LD - Lambayeque": "LD",
    "LI - Lima": "LI",
    "LO - Loreto": "LO",
    "MD - Madre de Dios": "MD",
    "MO - Moquegua": "MO",
    "PA - Pasco": "PA",
    "PI - Piura": "PI",
    "PU - Puno": "PU",
    "SM - San Martín": "SM",
    "TA - Tacna": "TA",
    "TU - Tumbes": "TU",
    "UC - Ucayali": "UC",
  },
  UY_STATES: {
    "AR - Artigas": "AR",
    "CA - Canelones": "CA",
    "CL - Cerro Largo": "CL",
    "CO - Colonia": "CO",
    "DU - Durazno": "DU",
    "FS - Flores": "FS",
    "FD - Florida": "FD",
    "LA - Lavalleja": "LA",
    "MA - Maldonado": "MA",
    "MO - Montevideo": "MO",
    "PA - Paysandú": "PA",
    "RN - Río Negro": "RN",
    "RV - Rivera": "RV",
    "RO - Rocha": "RO",
    "SL - Salto": "SL",
    "SJ - San José": "SJ",
    "SO - Soriano": "SO",
    "TT - Tacuarembó": "TT",
    "TY - Treinta y Tres": "TY",
  },
};

export const BRAZIL_STATES = {
  "AC - Acre": "AC",
  "AL - Alagoas": "AL",
  "AP - Amapá": "AP",
  "AM - Amazonas": "AM",
  "BA - Bahia": "BA",
  "CE - Ceará": "CE",
  "DF - Distrito Federal": "DF",
  "ES - Espírito Santo": "ES",
  "GO - Goiás": "GO",
  "MA - Maranhão": "MA",
  "MT - Mato Grosso": "MT",
  "MS - Mato Grosso do Sul": "MS",
  "MG - Minas Gerais": "MG",
  "PA - Pará": "PA",
  "PB - Paraíba": "PB",
  "PR - Paraná": "PR",
  "PE - Pernambuco": "PE",
  "PI - Piauí": "PI",
  "RJ - Rio de Janeiro": "RJ",
  "RN - Rio Grande do Norte": "RN",
  "RS - Rio Grande do Sul": "RS",
  "RO - Rondônia": "RO",
  "RR - Roraima": "RR",
  "SC - Santa Catarina": "SC",
  "SP - São Paulo": "SP",
  "SE - Sergipe": "SE",
  "TO - Tocantins": "TO",
};

export const MEXICO_STATES = {
  "AGS - Aguascalientes": "AGS",
  "BCN - Baja California": "BCN",
  "BCS - Baja California Sur": "BCS",
  "CAM - Campeche": "CAM",
  "CHS - Chiapas": "CHS",
  "CHI - Chihuahua": "CHI",
  "CDMX - Ciudad de MÃ©xico": "CDMX",
  "COA - Coahuila": "COA",
  "COL - Colima": "COL",
  "DGO - Durango": "DGO",
  "EM - Estado de MÃ©xico": "EM",
  "GTO - Guanajuato": "GTO",
  "GRO - Guerrero": "GRO",
  "HGO - Hidalgo": "HGO",
  "JAL - Jalisco": "JAL",
  "MICH - MichoacÃ¡n": "MICH",
  "MOR - Morelos": "MOR",
  "NAY - Nayarit": "NAY",
  "NL - Nuevo LeÃ³n": "NL",
  "OAX - Oaxaca": "OAX",
  "PUE - Puebla": "PUE",
  "QRO - QuerÃ©taro": "QRO",
  "QR - Quintana Roo": "QR",
  "SLP - San Luis PotosÃ­": "SLP",
  "SIN - Sinaloa": "SIN",
  "SON - Sonora": "SON",
  "TAB - Tabasco": "TAB",
  "TAM - Tamaulipas": "TAM",
  "TLAX - Tlaxcala": "TLAX",
  "VER - Veracruz": "VER",
  "YUC - YucatÃ¡n": "YUC",
  "ZAC - Zacatecas": "ZAC",
};

export const HS_CODES = {
  "Acessory (no-battery)": 903289,
  "Acessory (with battery)": 851822,
  "Audio Video": 711719,
  "Bags & Luggages": 420299,
  "Board Games": 950300,
  "Books & Collectibles": 490199,
  Cameras: 852580,
  "Computers & Laptops": 847130,
  Documents: 490199,
  "Dry Foods & Supplements": 210690,
  Fashion: 430310,
  Gaming: 950430,
  "Health & Beauty": 330499,
  "Home Appliances": 851640,
  "Home Decor": 442090,
  Jewelry: 711719,
  "Mobile Phone": 851712,
  "Pet Accessory": 420100,
  Shoes: 640590,
  "Sport & Leisures": 420291,
  Tablets: 847130,
  Toys: 950300,
  Watches: 910219,
};

export const CATEGORIES = [...Object.keys(HS_CODES), "Others"];

export const COUNTRIES_REGEX = [
  {
    name: "Andorra",
    iso: "AD",
    nationalTerm: "Código postal",
    regEx: "AD\\d{3}",
    mask: "XX###",
    maskLength: 5,
  },
  {
    name: "United Arab Emirates",
    iso: "AE",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Afghanistan",
    iso: "AF",
    nationalTerm: "Post code",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Antigua and Barbuda",
    iso: "AG",
    nationalTerm: "Post code",
    regEx: ".{1,255}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Anguilla",
    iso: "AI",
    nationalTerm: "Post code",
    regEx: "(AI-2640)",
    mask: "XX-####",
    maskLength: 6,
  },
  {
    name: "Albania",
    iso: "AL",
    nationalTerm: "Kodi postar",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Armenia",
    iso: "AM",
    nationalTerm: "Postal index",
    regEx: "(\\d{4})|(\\d{6})",
    mask: "####, ######",
    maskLength: 4,
  },
  {
    name: "Angola",
    iso: "AO",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Antarctica",
    iso: "AQ",
    nationalTerm: "Postcode",
    regEx: "-7151",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Argentina",
    iso: "AR",
    nationalTerm: "CPA",
    regEx: "([A-Z]\\d{4}[A-Z]{3})|([A-Z]\\d{4})",
    mask: "X####XXX, X####",
    maskLength: 5,
  },
  {
    name: "American Samoa",
    iso: "AS",
    nationalTerm: "Zip code (US)",
    regEx: "967\\d{2}(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Austria",
    iso: "AT",
    nationalTerm: "PLZ",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Australia",
    iso: "AU",
    nationalTerm: "Postcode",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Aruba",
    iso: "AW",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Azerbaijan",
    iso: "AZ",
    nationalTerm: "İndeks",
    regEx: "(AZ)(\\d{4})|(AZ )(\\d{4})",
    mask: "XX ####, XX####",
    maskLength: 6,
  },
  {
    name: "Bosnia and Herzegovina",
    iso: "BA",
    nationalTerm: "Poštanski broj",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Barbados",
    iso: "BB",
    nationalTerm: "Postal code",
    regEx: "BB\\d{5}",
    mask: "XX#####",
    maskLength: 7,
  },
  {
    name: "Bangladesh",
    iso: "BD",
    nationalTerm: "Post code",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Belgium",
    iso: "BE",
    nationalTerm: "Code postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Burkina Faso",
    iso: "BF",
    nationalTerm: "",
    regEx: "[1-9]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Bulgaria",
    iso: "BG",
    nationalTerm: "Пощенски код",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Bahrain",
    iso: "BH",
    nationalTerm: "Post code",
    regEx: "\\d{3}\\d?",
    mask: "###, ####",
    maskLength: 3,
  },
  {
    name: "Burundi",
    iso: "BI",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Benin",
    iso: "BJ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Bermuda",
    iso: "BM",
    nationalTerm: "Post codes",
    regEx: "[A-Z]{2} \\d{2}",
    mask: "XX ##",
    maskLength: 4,
  },
  {
    name: "Brunei Darussalam",
    iso: "BN",
    nationalTerm: "Kod",
    regEx: "[A-Z]{2}\\d{4}",
    mask: "XX####",
    maskLength: 6,
  },
  {
    name: "Bolivia",
    iso: "BO",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso: "BQ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Brazil",
    iso: "BR",
    nationalTerm: "CEP",
    regEx: "[0-9]{5}-[0-9]{3}",
    mask: "#####-###",
    maskLength: 8,
  },
  {
    name: "Bahamas",
    iso: "BS",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Bhutan",
    iso: "BT",
    nationalTerm: "Post code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Bouvet Island",
    iso: "BV",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Botswana",
    iso: "BW",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Belarus",
    iso: "BY",
    nationalTerm: "Индекс",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Belize",
    iso: "BZ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Canada",
    iso: "CA",
    nationalTerm: "Post code",
    regEx: "[A-Z][0-9][A-Z] [0-9][A-Z][0-9]",
    mask: "X#X #X#",
    maskLength: 6,
  },
  {
    name: "Cocos (Keeling) Islands",
    iso: "CC",
    nationalTerm: "Post code",
    regEx: "-6799",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Congo, the Democratic Republic of the",
    iso: "CD",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Central African Republic",
    iso: "CF",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Congo",
    iso: "CG",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Switzerland",
    iso: "CH",
    nationalTerm: "PLZ / Code postal",
    regEx: "[1-9]\\d{3}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Côte d'Ivoire",
    iso: "CI",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Cook Islands",
    iso: "CK",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Chile",
    iso: "CL",
    nationalTerm: "Código postal",
    regEx: "\\d{7}",
    mask: "#######",
    maskLength: 7,
  },
  {
    name: "Cameroon",
    iso: "CM",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "China",
    iso: "CN",
    nationalTerm: "郵政編碼",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Colombia",
    iso: "CO",
    nationalTerm: "Código postal",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Costa Rica",
    iso: "CR",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Cuba",
    iso: "CU",
    nationalTerm: "Código postal",
    regEx: "(CP)?\\d{5}",
    mask: "XX#####",
    maskLength: 7,
  },
  {
    name: "Cabo Verde",
    iso: "CV",
    nationalTerm: "CIP",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Curaçao",
    iso: "CW",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Christmas Island",
    iso: "CX",
    nationalTerm: "Postcode",
    regEx: "-6798",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Cyprus",
    iso: "CY",
    nationalTerm: "Postcode",
    regEx: "[1-9]\\d{3}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Czech Republic",
    iso: "CZ",
    nationalTerm: "PSČ",
    regEx: "[1-7][0-9]{2} [0-9]{2}|[1-7][0-9]{4}",
    mask: "### ##, #####",
    maskLength: 5,
  },
  {
    name: "Germany",
    iso: "DE",
    nationalTerm: "PLZ",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Djibouti",
    iso: "DJ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Denmark",
    iso: "DK",
    nationalTerm: "Postnummer",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Dominica",
    iso: "DM",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Dominican Republic",
    iso: "DO",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Algeria",
    iso: "DZ",
    nationalTerm: "Code postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Ecuador",
    iso: "EC",
    nationalTerm: "Código postal",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Estonia",
    iso: "EE",
    nationalTerm: "Postiindeks",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Egypt",
    iso: "EG",
    nationalTerm: "البريدي الرمز",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Eritrea",
    iso: "ER",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Spain",
    iso: "ES",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Ethiopia",
    iso: "ET",
    nationalTerm: "Postal code",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Finland",
    iso: "FI",
    nationalTerm: "Postinumero",
    regEx: "\\d{5}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Fiji",
    iso: "FJ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Falkland Islands (Malvinas)",
    iso: "FK",
    nationalTerm: "Postcode",
    regEx: "(FIQQ 1ZZ)",
    mask: "XXXX #XX",
    maskLength: 7,
  },
  {
    name: "Micronesia, Federated States of",
    iso: "FM",
    nationalTerm: "Zip code (US)",
    regEx: "9694\\d{1}(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Faroe Islands",
    iso: "FO",
    nationalTerm: "Postnummur (DK)",
    regEx: "\\d{3}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "France",
    iso: "FR",
    nationalTerm: "Code postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Gabon",
    iso: "GA",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    iso: "GB",
    nationalTerm: "Postcode",
    regEx:
      "([G][I][R] 0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})",
    mask: "XX# #XX, X# #XX",
    maskLength: 5,
  },
  {
    name: "Grenada",
    iso: "GD",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Georgia",
    iso: "GE",
    nationalTerm: "Post code",
    regEx: "\\d{4}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Guernsey",
    iso: "GG",
    nationalTerm: "Postcode (UK)",
    regEx:
      "(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
    mask: "XX# #XX",
    maskLength: 6,
  },
  {
    name: "Ghana",
    iso: "GH",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Gibraltar",
    iso: "GI",
    nationalTerm: "Postcode (UK)",
    regEx: "(GX11 1AA)",
    mask: "XX## #XX",
    maskLength: 7,
  },
  {
    name: "Greenland",
    iso: "GL",
    nationalTerm: "Postnummer (DK)",
    regEx: "39\\d{2}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Gambia",
    iso: "GM",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Guinea",
    iso: "GN",
    nationalTerm: "Code postal",
    regEx: "\\d{3}",
    mask: "#",
    maskLength: 1,
  },
  {
    name: "Equatorial Guinea",
    iso: "GQ",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Greece",
    iso: "GR",
    nationalTerm: "T.K.",
    regEx: "(\\d{3}) \\d{2}|\\d{5}",
    mask: "### ##, #####",
    maskLength: 5,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    iso: "GS",
    nationalTerm: "Post code",
    regEx: "(SIQQ 1ZZ)",
    mask: "XXXX #XX",
    maskLength: 7,
  },
  {
    name: "Guatemala",
    iso: "GT",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Guam",
    iso: "GU",
    nationalTerm: "Zip code (US)",
    regEx: "((969)[1-3][0-2])(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Guinea-Bissau",
    iso: "GW",
    nationalTerm: "Código postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Guyana",
    iso: "GY",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Hong Kong",
    iso: "HK",
    nationalTerm: "郵政編碼",
    regEx: "-999077",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Heard Island and McDonald Islands",
    iso: "HM",
    nationalTerm: "Postcode",
    regEx: "-7151",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Honduras",
    iso: "HN",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Croatia",
    iso: "HR",
    nationalTerm: "Poštanski broj",
    regEx: "[1-5]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Haiti",
    iso: "HT",
    nationalTerm: "Code postal",
    regEx: "(HT)(\\d{4})|(HT) (\\d{4})",
    mask: "XX####, XX ####",
    maskLength: 6,
  },
  {
    name: "Hungary",
    iso: "HU",
    nationalTerm: "Irányítószám",
    regEx: "[1-9]\\d{3}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Indonesia",
    iso: "ID",
    nationalTerm: "Kodepos",
    regEx: "[1-9]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Ireland",
    iso: "IE",
    nationalTerm: "Eircode",
    regEx: ".{1,255}",
    mask: "X## XX##",
    maskLength: 7,
  },
  {
    name: "Israel",
    iso: "IL",
    nationalTerm: "Postal code",
    regEx: "\\d{7}",
    mask: "#######",
    maskLength: 7,
  },
  {
    name: "Isle of Man",
    iso: "IM",
    nationalTerm: "Postcode (UK)",
    regEx:
      "(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}",
    mask: "XX# #XX",
    maskLength: 6,
  },
  {
    name: "India",
    iso: "IN",
    nationalTerm: "PIN code",
    regEx: "[1-9]\\d{5}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "British Indian Ocean Territory",
    iso: "IO",
    nationalTerm: "Postcode",
    regEx: "(BB9D 1ZZ)",
    mask: "XX#X #XX",
    maskLength: 7,
  },
  {
    name: "Iraq",
    iso: "IQ",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Iran, Islamic Republic of",
    iso: "IR",
    nationalTerm: "پستی کد",
    regEx: "\\d{5}[\\-]?\\d{5}",
    mask: "##########, #####-#####",
    maskLength: 10,
  },
  {
    name: "Iceland",
    iso: "IS",
    nationalTerm: "Póstnúmer",
    regEx: "[1-9]\\d{2}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Italy",
    iso: "IT",
    nationalTerm: "CAP",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Jersey",
    iso: "JE",
    nationalTerm: "Postcode (UK)",
    regEx: "JE[0-9]{1}[\\s]([\\d][A-Z]{2})",
    mask: "XX# #XX",
    maskLength: 6,
  },
  {
    name: "Jamaica",
    iso: "JM",
    nationalTerm: "Postal code",
    regEx: "(JM)[A-Z]{3}\\d{2}",
    mask: "XXXXX##",
    maskLength: 7,
  },
  {
    name: "Jordan",
    iso: "JO",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Japan",
    iso: "JP",
    nationalTerm: "郵便番号",
    regEx: "(\\d{3}-\\d{4})",
    mask: "###-####",
    maskLength: 7,
  },
  {
    name: "Kenya",
    iso: "KE",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Kyrgyzstan",
    iso: "KG",
    nationalTerm: "Почтовый индекс",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Cambodia",
    iso: "KH",
    nationalTerm: "Postal code",
    regEx: "\\d{5,6}",
    mask: "#####, ######, ######",
    maskLength: 5,
  },
  {
    name: "Kiribati",
    iso: "KI",
    nationalTerm: "",
    regEx: "KI\\d{4}",
    mask: "XX####",
    maskLength: 6,
  },
  {
    name: "Comoros",
    iso: "KM",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Saint Kitts and Nevis",
    iso: "KN",
    nationalTerm: "",
    regEx: "KN\\d{4}(\\-\\d{4})?",
    mask: "XX####, XX####, XX####-####, XX####-####",
    maskLength: 6,
  },
  {
    name: "Korea, Democratic People's Republic of",
    iso: "KP",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Korea, Republic of",
    iso: "KR",
    nationalTerm: "우편번호",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Kuwait",
    iso: "KW",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Cayman Islands",
    iso: "KY",
    nationalTerm: "Postcode (UK)",
    regEx: "[K][Y][0-9]{1}[-]([0-9]){4}",
    mask: "XX#-####",
    maskLength: 7,
  },
  {
    name: "Kazakhstan",
    iso: "KZ",
    nationalTerm: "Почтового индекса",
    regEx: "([A-Z]\\d{2}[A-Z]\\d[A-Z]\\d)|(\\d{6})",
    mask: "X##X#X#, ######",
    maskLength: 6,
  },
  {
    name: "Lao People's Democratic Republic",
    iso: "LA",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Lebanon",
    iso: "LB",
    nationalTerm: "Code postal",
    regEx: "\\d{4}( \\d{4})?",
    mask: "#### ####, ####",
    maskLength: 4,
  },
  {
    name: "Saint Lucia",
    iso: "LC",
    nationalTerm: "",
    regEx: "LC\\d{2} \\d{3}",
    mask: "XX## ###",
    maskLength: 7,
  },
  {
    name: "Liechtenstein",
    iso: "LI",
    nationalTerm: "PLZ (CH)",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Sri Lanka",
    iso: "LK",
    nationalTerm: "Postcode",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Liberia",
    iso: "LR",
    nationalTerm: "Postal code",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Lesotho",
    iso: "LS",
    nationalTerm: "Postal code",
    regEx: "\\d{3}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Lithuania",
    iso: "LT",
    nationalTerm: "Pašto kodas",
    regEx: "((LT)[\\-])?(\\d{5})",
    mask: "XX-#####, #####",
    maskLength: 5,
  },
  {
    name: "Luxembourg",
    iso: "LU",
    nationalTerm: "Code postal",
    regEx: "((L)[\\-])?(\\d{4})",
    mask: "####, X-####",
    maskLength: 4,
  },
  {
    name: "Latvia",
    iso: "LV",
    nationalTerm: "Pasta indekss",
    regEx: "((LV)[\\-])?(\\d{4})",
    mask: "XX-####, ####",
    maskLength: 4,
  },
  {
    name: "Libya",
    iso: "LY",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "##.##.####",
    maskLength: 8,
  },
  {
    name: "Morocco",
    iso: "MA",
    nationalTerm: "Code postal",
    regEx: "[1-9]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Moldova, Republic of",
    iso: "MD",
    nationalTerm: "Coduri poştale",
    regEx: "(MD[\\-]?)?(\\d{4})",
    mask: "XX####, XX-####, ####",
    maskLength: 4,
  },
  {
    name: "Montenegro",
    iso: "ME",
    nationalTerm: "Поштански код",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Madagascar",
    iso: "MG",
    nationalTerm: "Code postal",
    regEx: "\\d{3}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Marshall Islands",
    iso: "MH",
    nationalTerm: "Zip code (US)",
    regEx: "((969)[6-7][0-9])(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "North Macedonia",
    iso: "MK",
    nationalTerm: "Поштенски код",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Mali",
    iso: "ML",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Myanmar",
    iso: "MM",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Mongolia",
    iso: "MN",
    nationalTerm: "Zipcode",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Macao",
    iso: "MO",
    nationalTerm: "$郵政編碼",
    regEx: ".{1,255}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Northern Mariana Islands",
    iso: "MP",
    nationalTerm: "Zip code (US)",
    regEx: "9695\\d{1}(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Mauritania",
    iso: "MR",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Montserrat",
    iso: "MS",
    nationalTerm: "Post code",
    regEx: "MSR\\d{4}",
    mask: "XXX####",
    maskLength: 7,
  },
  {
    name: "Malta",
    iso: "MT",
    nationalTerm: "Kodiċi Postali",
    regEx:
      "[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}",
    mask: "XXX ####, XX##, XX ##, XXX####, XXX##, XXX ##",
    maskLength: 4,
  },
  {
    name: "Mauritius",
    iso: "MU",
    nationalTerm: "Post code",
    regEx: "([0-9A-R]\\d{4})",
    mask: "X####, #####",
    maskLength: 5,
  },
  {
    name: "Maldives",
    iso: "MV",
    nationalTerm: "Post code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Malawi",
    iso: "MW",
    nationalTerm: "",
    regEx: "\\d{6}",
    mask: "######, ######",
    maskLength: 6,
  },
  {
    name: "Mexico",
    iso: "MX",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Malaysia",
    iso: "MY",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Mozambique",
    iso: "MZ",
    nationalTerm: "Código postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Namibia",
    iso: "NA",
    nationalTerm: "",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "New Caledonia",
    iso: "NC",
    nationalTerm: "Code postal (FR)",
    regEx: "988\\d{2}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Niger",
    iso: "NE",
    nationalTerm: "Code postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Norfolk Island",
    iso: "NF",
    nationalTerm: "Post code",
    regEx: "-2899",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Nigeria",
    iso: "NG",
    nationalTerm: "Postal code",
    regEx: "[1-9]\\d{5}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Nicaragua",
    iso: "NI",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Netherlands",
    iso: "NL",
    nationalTerm: "Postcode",
    regEx: "[1-9]\\d{3} [A-Z]{2}|[1-9]\\d{3}[A-Z]{2}",
    mask: "#### XX, ####XX",
    maskLength: 6,
  },
  {
    name: "Norway",
    iso: "NO",
    nationalTerm: "Postnummer",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Nepal",
    iso: "NP",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Nauru",
    iso: "NR",
    nationalTerm: "",
    regEx: "(NRU68)",
    mask: "XXX##",
    maskLength: 5,
  },
  {
    name: "Niue",
    iso: "NU",
    nationalTerm: "",
    regEx: "-9974",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "New Zealand",
    iso: "NZ",
    nationalTerm: "Postcode",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Oman",
    iso: "OM",
    nationalTerm: "Postal code",
    regEx: "\\d{3}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Panama",
    iso: "PA",
    nationalTerm: "",
    regEx: "\\d{4}",
    mask: "####, ####",
    maskLength: 4,
  },
  {
    name: "Peru",
    iso: "PE",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "French Polynesia",
    iso: "PF",
    nationalTerm: "Code postal (FR)",
    regEx: "((987)\\d{2})",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Papua New Guinea",
    iso: "PG",
    nationalTerm: "Postal code",
    regEx: "\\d{3}",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Philippines",
    iso: "PH",
    nationalTerm: "Zipcode",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Pakistan",
    iso: "PK",
    nationalTerm: "Post code",
    regEx: "[1-9]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Poland",
    iso: "PL",
    nationalTerm: "Kod pocztowy",
    regEx: "[0-9]{2}[-]([0-9]){3}",
    mask: "##-###",
    maskLength: 5,
  },
  {
    name: "Pitcairn",
    iso: "PN",
    nationalTerm: "Post code",
    regEx: "(PCR9 1ZZ)",
    mask: "XXX# #XX",
    maskLength: 7,
  },
  {
    name: "Palestine, State of",
    iso: "PS",
    nationalTerm: "Post code",
    regEx: "(P[1-9]\\d{6})|(\\d{3}-\\d{3})",
    mask: "###-###, X#######",
    maskLength: 6,
  },
  {
    name: "Portugal",
    iso: "PT",
    nationalTerm: "Código postal",
    regEx: "[1-9]\\d{3}((-)\\d{3})",
    mask: "####-###",
    maskLength: 7,
  },
  {
    name: "Palau",
    iso: "PW",
    nationalTerm: "Postal code",
    regEx: "(96939|96940)",
    mask: "#####, #####",
    maskLength: 5,
  },
  {
    name: "Paraguay",
    iso: "PY",
    nationalTerm: "Código postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Qatar",
    iso: "QA",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Romania",
    iso: "RO",
    nationalTerm: "Cod poştal",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Serbia",
    iso: "RS",
    nationalTerm: "Поштански код",
    regEx: "\\d{5,6}",
    mask: "#####, ######",
    maskLength: 5,
  },
  {
    name: "Russian Federation",
    iso: "RU",
    nationalTerm: "Почтовый индекс",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Rwanda",
    iso: "RW",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Saudi Arabia",
    iso: "SA",
    nationalTerm: "Postal code",
    regEx: "[1-8]\\d{4}([\\-]\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Solomon Islands",
    iso: "SB",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Seychelles",
    iso: "SC",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Sudan",
    iso: "SD",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Sweden",
    iso: "SE",
    nationalTerm: "Postnummer",
    regEx: "[1-9]\\d{2} \\d{2}",
    mask: "### ##",
    maskLength: 5,
  },
  {
    name: "Singapore",
    iso: "SG",
    nationalTerm: "Postal code",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    iso: "SH",
    nationalTerm: "Postcode (UK)",
    regEx: "(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)",
    mask: "XXXX #XX, XXXX #XX, XXXX #XX",
    maskLength: 7,
  },
  {
    name: "Slovenia",
    iso: "SI",
    nationalTerm: "Poštna številka",
    regEx: "[1-9]\\d{3}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Slovakia",
    iso: "SK",
    nationalTerm: "PSČ",
    regEx: "(\\d{3} \\d{2})|\\d{5}",
    mask: "### ##, #####",
    maskLength: 5,
  },
  {
    name: "Sierra Leone",
    iso: "SL",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "San Marino",
    iso: "SM",
    nationalTerm: "CAP (IT)",
    regEx: "(4789\\d)",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Senegal",
    iso: "SN",
    nationalTerm: "Code postal",
    regEx: "[1-8]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Somalia",
    iso: "SO",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "XX #####",
    maskLength: 7,
  },
  {
    name: "Suriname",
    iso: "SR",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "South Sudan",
    iso: "SS",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "São Tomé and Príncipe",
    iso: "ST",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "El Salvador",
    iso: "SV",
    nationalTerm: "Código postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso: "SX",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Syrian Arab Republic",
    iso: "SY",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Swaziland",
    iso: "SZ",
    nationalTerm: "Postal code",
    regEx: "([A-Z]\\d{3})",
    mask: "X###",
    maskLength: 4,
  },
  {
    name: "Turks and Caicos Islands",
    iso: "TC",
    nationalTerm: "Postcode (UK)",
    regEx: "(TKCA 1ZZ)",
    mask: "XXXX #XX",
    maskLength: 7,
  },
  {
    name: "Chad",
    iso: "TD",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "French Southern Territories",
    iso: "TF",
    nationalTerm: "Code postal",
    regEx: ".{1,255}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Togo",
    iso: "TG",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Thailand",
    iso: "TH",
    nationalTerm: "รหัสไปรษณีย์",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Tajikistan",
    iso: "TJ",
    nationalTerm: "Нишонаи почта",
    regEx: "7\\d{5}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Tokelau",
    iso: "TK",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Timor-Leste",
    iso: "TL",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Turkmenistan",
    iso: "TM",
    nationalTerm: "Почтовый индекс",
    regEx: "7\\d{5}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Tunisia",
    iso: "TN",
    nationalTerm: "Code postal",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Tonga",
    iso: "TO",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Turkey",
    iso: "TR",
    nationalTerm: "Posta kodu",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Trinidad and Tobago",
    iso: "TT",
    nationalTerm: "",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Tuvalu",
    iso: "TV",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Taiwan (Province of China)",
    iso: "TW",
    nationalTerm: "郵遞區號",
    regEx: "(\\d{3}\\-\\d{3})|(\\d{3}[-]\\d{2})|(\\d{6})|(\\d{3})",
    mask: "###-##, ###, ######, ###(-)###",
    maskLength: 3,
  },
  {
    name: "Tanzania, United Republic of",
    iso: "TZ",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Ukraine",
    iso: "UA",
    nationalTerm: "Поштовий індекс",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Uganda",
    iso: "UG",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "United States Minor Outlying Islands",
    iso: "UM",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "United States of America",
    iso: "US",
    nationalTerm: "Zip code (US)",
    regEx: "\\d{5}(-\\d{4})?",
    mask: "#####, #####-####, #####, #####-####",
    maskLength: 5,
  },
  {
    name: "Uruguay",
    iso: "UY",
    nationalTerm: "Código postal",
    regEx: "[1-9]\\d{4}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Uzbekistan",
    iso: "UZ",
    nationalTerm: "Почтовый индекс",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Holy See",
    iso: "VA",
    nationalTerm: "CAP (IT)",
    regEx: "-120",
    mask: "###",
    maskLength: 3,
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso: "VC",
    nationalTerm: "Postal code",
    regEx: "(VC)(\\d{4})",
    mask: "XX####",
    maskLength: 6,
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    iso: "VE",
    nationalTerm: "Código postal",
    regEx: "[1-8]\\d{3}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Virgin Islands (British)",
    iso: "VG",
    nationalTerm: "Postal code",
    regEx: "(VG11)[0-6][0]",
    mask: "XX####",
    maskLength: 6,
  },
  {
    name: "Virgin Islands (U.S.)",
    iso: "VI",
    nationalTerm: "Zip code (US)",
    regEx: "008\\d{2}(-\\d{4})?",
    mask: "#####, #####-####",
    maskLength: 5,
  },
  {
    name: "Viet Nam",
    iso: "VN",
    nationalTerm: "Mã bưu chính",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
  },
  {
    name: "Vanuatu",
    iso: "VU",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "Wallis and Futuna",
    iso: "WF",
    nationalTerm: "Code postal (FR)",
    regEx: "(986)\\d{2}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Samoa",
    iso: "WS",
    nationalTerm: "Postal code",
    regEx: "WS[1-2]\\d{3}",
    mask: "XX####",
    maskLength: 6,
  },
  {
    name: "Yemen",
    iso: "YE",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
  {
    name: "South Africa",
    iso: "ZA",
    nationalTerm: "Postal code",
    regEx: "\\d{4}",
    mask: "####",
    maskLength: 4,
  },
  {
    name: "Zambia",
    iso: "ZM",
    nationalTerm: "Postal code",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
  },
  {
    name: "Zimbabwe",
    iso: "ZW",
    nationalTerm: "",
    regEx: ".{1,255}",
    mask: "",
    maskLength: 0,
  },
];

export const COUNTRIES_RECEIVER_REGEX = [
  {
    name: "Argentina",
    iso: "AR",
    nationalTerm: "CPA",
    regEx: "([A-Z]\\d{4}[A-Z]{3})|([A-Z]\\d{4})",
    mask: "X####XXX, X####",
    maskLength: 5,
    documentName: "CUIT/CUIL",
    documentNameHelpText:
      "Clave Única de Identificación Tributaria (CUIT) Clave Única de Identificación Laboral (CUIL)",
    documentFormat: "20-12345678-0",
    cepFormat: "A1234BCD",
    documentMasks: ["XX-XXXXXXXX-X"],
  },
  {
    name: "Brazil",
    iso: "BR",
    nationalTerm: "CEP",
    regEx: "[0-9]{5}-[0-9]{3}",
    mask: "#####-###",
    maskLength: 8,
    documentName: "CPF/CNPJ",
    documentNameHelpText:
      "Cadastro de Pessoas Físicas (CPF) Cadastro Nacional da Pessoa Jurídica (CNPJ)",
    documentFormat: "123.456.789-01",
    cepFormat: "12345-678",
    documentMasks: ["###.###.###-##", "##.###.###/####-##"],
  },
  {
    name: "Chile",
    iso: "CL",
    nationalTerm: "Código postal",
    regEx: "\\d{7}",
    mask: "#######",
    maskLength: 7,
    documentName: "RUT",
    documentNameHelpText:
      "Clave Única de Registro de Población (CURP) Registro Federal de Contribuyentes (RFC)",
    documentFormat: "12.345.678-A",
    cepFormat: "1234567",
    documentMasks: ["##.###.###-X"],
  },
  {
    name: "Colombia",
    iso: "CO",
    nationalTerm: "Código postal",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
    documentName: "NIT",
    documentNameHelpText: "Número de Identificación Tributaria (NIT)",
    documentFormat: "1234567890",
    cepFormat: "123456",
    documentMasks: ["XXXXXXXXXXXX"],
  },
  {
    name: "Ecuador",
    iso: "EC",
    nationalTerm: "Código postal",
    regEx: "\\d{6}",
    mask: "######",
    maskLength: 6,
    documentName: "RUC",
    documentNameHelpText: "Registro Único de Contribuyentes (RUC)",
    documentFormat: "1234567890",
    cepFormat: "123456",
    documentMasks: ["XXXXXXXXXXXX"],
  },
  {
    name: "Mexico",
    iso: "MX",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
    documentName: "CURP/RFC",
    documentNameHelpText:
      "Clave Única de Registro de Población (CURP) Registro Federal de Contribuyentes (RFC)",
    documentFormat: "ABCD012345EFGHIJ67",
    cepFormat: "12345",
    documentMasks: ["SSSS######SSX"],
  },
  {
    name: "Peru",
    iso: "PE",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
    documentName: "DNI/RUC",
    documentNameHelpText:
      "Documento Nacional de Identidad (DNI) Registro Único de Contribuyentes (RUC)",
    documentFormat: "12345678-A",
    cepFormat: "12345",
    documentMasks: ["########", "########-#"],
  },
  {
    name: "Uruguay",
    iso: "UY",
    nationalTerm: "Código postal",
    regEx: "\\d{5}",
    mask: "#####",
    maskLength: 5,
    documentName: "RUT",
    documentNameHelpText: "Registro Único Tributario (RUT)",
    documentFormat: "12345678-0",
    cepFormat: "12345",
    documentMasks: ["XXXXXXXXX-X"],
  },
];
export const CONDITIONS_ATTRIBUTES = {
  ALL_SHIPMENTS: "All Shipments",
  CONTAINS_CATEGORY: "Contains Category",
  DESTINATION_COUNTRY: "Destination Country",
  DESTINATION_STATE: "Destination State",
  ITEM_SKU: "Item SKU",
  NUMBER_OF_ITEMS: "Number of Items",
  CUSTOMER_POSTAL_CODE: "Customer Postal Code",
  SELLER_PRICE: "Seller Price",
  SHIPMENT_WEIGHT: "Shipment Weight",
  CHANNEL: "Channel",
};

export const RULE_OPERATORS = {
  SHIPMENT_CONTAINS: "shipment_contains",
  SHIPMENT_DOES_NOT_CONTAIN: "shipment_does_not_contain",
  SHIPMENT_ONLY_CONTAINS: "shipment_only_contains",
  IS_ANY_OF: "is_any_of",
  IS_NONE_OF: "is_none_of",
  STARTS_WITH: "starts_with",
  GREATER_THAN: "greater_than",
  GREATER_THAN_OR_EQUAL_TO: "greater_than_or_equal_to",
  LESS_THAN: "less_than",
  LESS_THAN_OR_EQUAL_TO: "less_than_or_equal_to",
  EQUALS_TO: "equals_to",
};

export const RULE_PRIORITY = {
  VERY_HIGH: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4,
  VERY_LOW: 5,
};

export const ICON_TERMS = ["DDP", "DDU"];
export const PROFILES = {
  NOBORDIST: "nobordist",
  CARRIER: "carrier",
  SELLER: "seller",
};

export const ORDER_STATUS = {
  NEW: 100,
  READY_TO_SHIP: 300,
  PICKUP: 400,
  SHIPPED: 500,
  IN_TRANSIT: 600,
  OUT_FOR_DELIVERY: 700,
  FAILED_ATTEMP: 800,
  DELIVERED: 900,
  DELIVERED_FAILED: 1000,
  PARTIAL_DELIVERY: 1300,
  PARTIAL_DELIVERY_FAILED: 1400,
};

export const LOCALES = {
  PT_BR: "pt_BR",
  EN_US: "en",
  CN: "zh_CN",
};

export const FiscalPaymentType = {
  ICMS: "icms",
  DARF: "darf",
  FINE689: "fine689",
  FINE702: "fine702",
  FINE703: "fine703",
  FINE711: "fine711",
  FINE715: "fine715",
  FINE725: "fine725",
  FINE728: "fine728",
};

<template>
  <div class="relative fix-top" :class="`${size}`">
    <div v-click-outside="closeCollapse">
      <a
        class="nb-button notification-button-option"
        :id="`${id}-1`"
        :class="`${size}`"
        v-b-toggle
        :href="`#${id}`"
        @click.prevent
      >
        <span
          v-if="notifications.count > 0"
          class="badge badge-pill badge-danger notification-badge"
          style="position: absolute; top: -4px; right: 1px; font-size: 0.55rem"
          >{{ notifications.count }}</span
        >
        <slot>Options</slot>
      </a>
      <b-collapse
        :id="id"
        class="mt-0 colapse-card-wrapper"
        :style="`width: ${optionsWidth}px; ${expandTo}: 0`"
        v-model="visible"
      >
        <b-card class="notifications-card">
          <div class="d-flex justify-content-between mb-3">
            <div>
              {{ $t("myNotificationsListPage.title") }}
            </div>
            <div>
              <!-- <a href="/notifications/settings" class="link-2">{{ $t('settingsPage.title') }}</a> -->
              <!-- 
              <router-link :to="`/notifications/settings`" class="link-2">
                {{ $t('settingsPage.title') }}
              </router-link> 
              -->
            </div>
          </div>
          <div
            v-if="notifications.count === 0"
            class="notification-button-options d-flex justify-content-start"
            @click="
              closeCollapse();
              goTo('/notifications/notifications_list');
            "
          >
            <div class="notification-status">
              <NbStatus :type="''" />
            </div>
            <div>
              <span class="notification-message-title">
                You don't have new notifications</span
              >
            </div>
          </div>
          <div
            v-for="(notification, index) in lastTenNotifications"
            :key="index + '-notf'"
            :class="notification.disabled ? 'text-disabled' : ''"
            @click="notification.disabled ? null : emitClicked(notification)"
          >
            <div
              class="notification-button-options d-flex justify-content-start"
              @click="
                goTo('/notifications/notifications_list', notification.id)
              "
            >
              <div class="notification-status">
                <NbStatus :type="getStatusType(notification)" />
              </div>
              <div>
                <span class="notification-message-title">
                  {{
                    notification.type == "seller_notifications"
                      ? capitalizeWords(
                          sellerNotificationTitles[notification.title] || "-"
                        )
                      : capitalizeWords(
                          userNotificationTitles[notification.title] || "-"
                        )
                  }}:
                </span>
                <span class="notification-message-body">
                  {{ notification.body }}
                </span>
                <div class="notification-date mt-2">
                  {{
                    $helpers.formatShortTime(notification.created_at, {
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                  •
                  {{
                    $helpers.formatShortDate(notification.created_at, {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }}
                  ({{ timeAgo(notification.created_at) }})
                </div>
              </div>
            </div>
          </div>
          <router-link
            :to="`/notifications/notifications_list`"
            class="link-2 all-notification-link notification-button-options"
          >
            {{ $t("myNotificationsListPage.allNotifications") }} >
          </router-link>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { directive } from "v-click-outside";
import NbStatus from "@/components/alerts/NbStatus.vue";

import NotificationService from "@/services/NotificationService";
const notificationService = new NotificationService();

export default {
  name: "NbOption",
  directives: { clickOutside: directive },
  components: { NbStatus },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionsWidth: {
      type: [Number, String],
      required: false,
    },
    expandTo: {
      type: String,
      default: "bottom",
    },
    notifications: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userNotificationTitles: {},
      sellerNotificationTitles: {},
      content: this.value,
      visible: false,
    };
  },
  created() {
    this.getTitles();
  },
  methods: {
    getTitles() {
      notificationService.getSellerNotificationsTitles().then((response) => {
        this.sellerNotificationTitles = response.data.data;
      });
      notificationService.getUserNotificationsTitles().then((response) => {
        this.userNotificationTitles = response.data.data;
      });
    },
    emitClicked(option) {
      this.content = option.value;
      this.$emit("input", option.value);
    },
    closeCollapse() {
      this.visible = false;
      //this.$router.push('/notifications/notifications_list');
      //document.getElementById(`${this.id}-1`).click()
    },
    goTo(name, id) {
      window.location.href = `${name}${id ? "?notificationId=" + id : ""}`;
      /* 
      this.$nextTick(() => {
        this.$router.push({
          path: name,
          query: { notificationId: id },
        });
      }); */
    },
    getStatusType(notification) {
      let titles = { ...this.userNotificationTitles };
      if (notification.type == "seller_notifications") {
        titles = { ...this.sellerNotificationTitles };
      }

      if (notification.is_new) {
        if (titles[notification.title] === "finance") {
          return "success";
        }
        if (titles[notification.title] === "tracking_issue") {
          return "warning";
        }
        if (titles[notification.title] === "insufficient_balance") {
          return "danger";
        }
        return null;
      }
      return "";
    },
    capitalizeWords(string) {
      if (typeof string === "string") {
        string = string.replace("_", " ");
        string = string.replace("-", " ");
        return string.replace(/(?:^|\s)\S/g, (stringChar) => {
          return stringChar.toUpperCase();
        });
      }
      if (typeof string === "number") {
        return string;
      }
      return string;
    },
    timeAgo(time) {
      if (typeof time === "string") {
        time = +new Date(time);
      } else if (typeof time === "object") {
        if (time.constructor === Date) time = time.getTime();
      } else {
        time = +new Date();
      }

      const timeFormat = [
        [60, this.$t("myNotificationsPage.seconds"), 1],
        [120, this.$t("myNotificationsPage.minuteAgo"), "1 minute from now"],
        [3600, this.$t("myNotificationsPage.minutes"), 60],
        [7200, this.$t("myNotificationsPage.hourAgo"), "1 hour from now"],
        [86400, this.$t("myNotificationsPage.hours"), 3600],
        [172800, this.$t("myNotificationsPage.yesterday"), "Tomorrow"],
        [604800, this.$t("myNotificationsPage.days"), 86400],
        [1209600, this.$t("myNotificationsPage.lastWeek"), "Next week"],
        [2419200, this.$t("myNotificationsPage.weeks"), 604800],
        [4838400, this.$t("myNotificationsPage.lastMonth"), "Next month"],
        [29030400, this.$t("myNotificationsPage.months"), 2419200],
        [58060800, this.$t("myNotificationsPage.lastYear"), "Next year"],
        [2903040000, this.$t("myNotificationsPage.years"), 29030400],
        [5806080000, "Last century", "Next century"],
        [58060800000, "centuries", 2903040000],
      ];
      let seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      let i = 0;
      let format = 0;
      //Eslint maldito sacaneando a atribuição do valor em format
      while ((format = timeFormat[i++]))
        // eslint-disable-line
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
  computed: {
    lastTenNotifications() {
      //const lastMessage = {is_new: false, title:'', body:'and more', created_at:'',}
      if (this.notifications.elements.length > 0) {
        let finalNotifications = this.notifications.elements.slice(0, 10);
        //finalNotifications.push(lastMessage)
        return finalNotifications;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.notification-badge {
  transition: all 0.3s ease;
  padding: 3px 5px 2px 5px;
}
.relative.fix-top {
  height: calc(40px - 0.44rem);
}
.relative.fix-top.sm {
  height: calc(32px - 0.44rem);
}
.fix-top {
  margin-top: 0.44rem;
}
.nb-button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 11px 1.19rem;
  font: normal normal 600 12px/18px Nunito Sans;
  transition: 500ms;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}
.nb-button.notification-button-option {
  border: 1px solid var(--gray-05);
  color: var(--black);
  background-color: var(--gray-05);
  transition: 500ms;
}
.nb-button.notification-button-option:hover {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}
.nb-button.notification-button-option:focus {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
  /* 
  border: 1px solid var(--gray-60);
  background: var(--gray-05);
   */
}
.nb-button.button-icon {
  padding: 0.44rem 0.94rem;
}
.nb-button.sm {
  font: normal normal bold 12px/18px Nunito Sans;
  padding: 7px 0.61rem;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
}
.nb-button.xs {
  font: normal normal 600 14px/20px Nunito Sans;
  padding: 0.3rem 0.5rem;
}
.notification-button-options:hover,
.notification-button-options > .button-label:hover {
  cursor: pointer;
}
.button.select-button.disabled,
.text-disabled {
  color: var(--gray-60);
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.text-disabled > .notification-button-options:hover,
.text-disabled > .notification-button-options > .button-label:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.button-label {
  color: var(--black);
  text-align: center;
  font: normal normal normal 12px/18px Nunito Sans;
}

.notifications-card > .card-body {
  padding: 0.93rem 0.88rem;
  max-height: 80vh;
  overflow-y: auto;
}
.card-body {
  z-index: 3;
  background: var(--gray-05);
  box-shadow: 4px 4px 12px var(--gray-10);
  border-radius: 4px 4px 0px 0px;
  padding: 0px;
  border-radius: 4px !important;

  /* border: 1px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.card {
  border-top: 0px !important;
  border-right: 1px solid var(--gray-05) !important;
  border-bottom: 1px solid var(--gray-05) !important;
  border-left: 1px solid var(--gray-05) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px;
  background: transparent;
  flex-direction: column;
  justify-content: center;
}
.relative {
  position: relative;
}
.notification-button-options {
  text-align: left;
  /* width: 100%; */
  padding: 1rem 0.5rem;
  border-top: 1px solid var(--gray-10);
}
.notification-button-options:hover {
  background-color: var(--gray-10);
}

.colapse-card-wrapper {
  position: absolute;
  top: 2.3rem;
  width: 100%;
}
.all-notification-link {
  position: sticky;

  display: block;
  padding: 0.6rem 0.5rem;
  border-top: 0px solid var(--gray-10);
  border-radius: 4px;
  bottom: -16px;
  background: var(--gray-05);
}
.notification-status {
  margin-right: 0.5rem;
  height: 6px;
  width: 6px;
}
.notification-date {
  color: var(--gray-60);
  font: normal normal normal 12px/16px Nunito Sans;
  letter-spacing: 0px;
}
.notification-message-title {
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
.notification-message-body {
  text-align: left;
  font: normal normal bold 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Components from "../components/Components";
import ComponentsTables from "../components/ComponentsTables";
import NProgress from "nprogress";

const Home = () => import("../views/home/HomeView.vue");
const Dashboard = () => import("../views/dashboard/DashboardView.vue");

const Login = () =>
  import(/* webpackChunkName: "login" */ "../views/logins/Login");
const TotalLogin = () =>
  import(/* webpackChunkName: "login" */ "../views/logins/TotalLogin");
const AsiaLogin = () =>
  import(/* webpackChunkName: "login" */ "../views/logins/AsiaLogin");
const EcrossLogin = () =>
  import(/* webpackChunkName: "login" */ "../views/logins/EcrossLogin");
const AnjunLogin = () =>
  import(/* webpackChunkName: "login" */ "../views/logins/AnjunLogin");
const UserProfile = () =>
  import(/* webpackChunkName: "user-profile" */ "../views/account/UserProfile");
const UserProfileEdit = () =>
  import(
    /* webpackChunkName: "user-profile" */ "../views/account/UserProfileEdit"
  );
const UserProfileSaldo = () =>
  import(
    /* webpackChunkName: "user-profile" */ "../views/account/UserProfileSaldo"
  );
const Users = () =>
  import(/* webpackChunkName: "user-profile" */ "../views/account/Users.vue");

import Erro404 from "../views/Erro404";

//const OrdersCreate = () =>
//import(/* webpackChunkName: "orders" */ "../views/orders/Create");
const OrderCreate = () =>
  import(/* webpackChunkName: "orders" */ "../views/orders/OrderCreate");
const OrdersList = () =>
  import(/* webpackChunkName: "orders" */ "../views/orders/List");
const OrdersIndex = () =>
  import(/* webpackChunkName: "orders" */ "../views/orders/Index");
const OrdersView = () =>
  import(/* webpackChunkName: "orders" */ "../views/orders/View");
const OrdersRejected = () =>
  import(/* webpackChunkName: "orders" */ "../views/orders/Rejected");

const OrdersImport = () =>
  import(/* webpackChunkName: "orders-import" */ "../views/orders/Import");
const VolumeReverse = () =>
  import(/* webpackChunkName: "volumes" */ "../views/orders/VolumeReverse");
const VolumeReverseView = () =>
  import(/* webpackChunkName: "volumes" */ "../views/orders/VolumeReverseView");

const AmazonFiles = () =>
  import(/* webpackChunkName: "amazon" */ "../views/orders/AmazonFiles");
const AmazonImport = () =>
  import(/* webpackChunkName: "amazon" */ "../views/orders/AmazonImport");

const PickupDropoff = () => import("../views/orders/PickupDropoff");
const DutiesAndTaxes = () => import("../views/orders/DutiesAndTaxes");
const TrackingLogView = () => import("../views/orders/TrackingLogView");

const ImportResponse = () =>
  import(
    /* webpackChunkName: "orders-import" */ "../views/orders/ImportResponse"
  );

const FiscalPayments = () => import("../views/orders/FiscalPayments.vue");

const OverpacksIndex = () =>
  import(/* webpackChunkName: "overpacks" */ "../views/overpacks/Index");
const OverpacksView = () =>
  import(/* webpackChunkName: "overpacks" */ "../views/overpacks/View");
const Overpacks = () =>
  import(
    /* webpackChunkName: "overpacks" */ "../views/overpacks/Overpacks.vue"
  );

const UsersCreate = () =>
  import(/* webpackChunkName: "users-settings" */ "../views/settings/Create");
const UsersView = () =>
  import(/* webpackChunkName: "users-settings" */ "../views/settings/View");
const UsersEdit = () =>
  import(/* webpackChunkName: "users-settings" */ "../views/settings/Edit");

const AccountIndex = () =>
  import(/* webpackChunkName: "account" */ "../views/account/Index");
const Company = () =>
  import(/* webpackChunkName: "account" */ "../views/account/Company");

const SettingsIndex = () => import("../views/settings/Index");
const SettingsShippingRules = () => import("../views/settings/ShippingRules");
const ProductsLogImport = () => import("../views/products/LogImport");
const SettingsCustomers = () => import("../views/settings/Customers");
const PasswordNew = () => import("../views/PasswordNew");
const BoxesProducts = () => import("../views/settings/BoxesProducts.vue");
const Documents = () => import("../views/settings/Documents");
const Insurance = () => import("../views/settings/Insurance");
const QuickStart = () => import("../views/settings/QuickStarts");
const Advanced = () => import("../views/settings/Advanced");
const SalesChannelsSettings = () => import("../views/settings/SalesChannels");

const Printing = () => import("../views/settings/Printings");
const Accessibility = () => import("../views/settings/Accessibility");
const TrackingPageSettings = () => import("../views/settings/TrackingPage");
const TrackingsIndex = () => import("../views/trackings/Index");
const TrackingsView = () => import("../views/trackings/View");
const TrackMyOrder = () => import("../views/TrackMyOrder.vue");
const QuotesIndex = () => import("../views/quotes/Index");
const QuotesNew = () => import("../views/quotes/New");

const NotificationIndex = () => import("../views/notifications/Index");
const NotifcationSettings = () => import("../views/notifications/Settings");
const GroupsConfiguration = () =>
  import("../views/notifications/GroupsConfiguration");
const GroupConfigurationView = () =>
  import("../views/notifications/GroupConfigurationView");
const GroupConfigurationEdit = () =>
  import("../views/notifications/GroupConfigurationEdit");
const CreateGroupConfiguration = () =>
  import("../views/notifications/CreateGroupConfiguration");

const IntegrationsIndex = () =>
  import(
    /* webpackChunkName: "integrations" */ "../views/integrations/Index.vue"
  );
const CouriersList = () =>
  import(
    /* webpackChunkName: "integrations" */ "../views/integrations/CouriersList.vue"
  );
const ChannelsList = () =>
  import(
    /* webpackChunkName: "integrations" */ "../views/integrations/ChannelsList.vue"
  );
const PriceTables = () =>
  import(
    /* webpackChunkName: "integrations" */ "../views/integrations/PriceTables.vue"
  );

import CurrentVersion from "../views/CurrentVersion.vue";
import FinancesIndex from "../views/finances/seller_accounts/Index";
const Downloads = () => import("../views/Downloads.vue");
const NotificationsList = () =>
  import("../views/notifications/NotificationsList.vue");

const Contracts = () => import("../views/accounts/Contracts.vue");

import Helpers from "../helpers";
import UserService from "../services/UserService";
import UsersService from "../services/UsersService";
import GroupService from "../services/GroupService";
import { removePlatformHook } from "./hooks/remove-platform";
const helpers = Helpers;
const userService = new UserService();
const usersService = new UsersService();
const groupService = new GroupService();

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      searchData: "home",
      visible: true,
      heading: "general",
      label: "HomeDashboard",
      icon: "home",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      searchData: "dashboard",
      visible: true,
      heading: "general",
      label: "Dashboard",
      icon: "grid",
    },
  },
  {
    path: "/components",
    name: "components",
    component: Components,
    meta: {
      visible: false,
      label: "Components",
    },
  },
  {
    path: "/components/tables",
    name: "componentstables",
    component: ComponentsTables,
    meta: {
      visible: false,
      label: "ComponentsTables",
    },
  },
  {
    path: "/login",
    name: "loginTemplate",
    component: Login,
    beforeEnter: removePlatformHook,
    meta: {
      visible: false,
    },
  },
  {
    path: "/nb-login",
    alias: [
      "/total-login",
      "/asia-login",
      "/e-cross-login",
      "/anjun-login",
      "/tiendamia-login",
      "/mirakl-login",
      "/skypostal-login",
      "/jt-login",
      "/correios-login",
      "/shopee-login",
      "/loggi-login",
      "/shipsmart-login",
    ],
    name: "login",
    component: Login,
    beforeEnter: removePlatformHook,
    meta: {
      visible: false,
      label: "Login",
    },
  },

  {
    path: "/total-login-test-test",
    name: "total_login",
    component: TotalLogin,
    meta: {
      visible: false,
      label: "TotalLogin",
    },
  },
  {
    path: "/asia-login-test",
    name: "asia_login",
    component: AsiaLogin,
    meta: {
      visible: false,
      label: "AsiaLogin",
    },
  },
  {
    path: "/e-cross-login-test",
    name: "ecross_login",
    component: EcrossLogin,
    meta: {
      visible: false,
      label: "EcrossLogin",
    },
  },
  {
    path: "/anjun-login-test",
    name: "anjun_login",
    component: AnjunLogin,
    meta: {
      visible: false,
      label: "AnjunLogin",
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: FinancesIndex,
    meta: {
      visible: false,
      label: "Finances",
    },
  },
  {
    path: "/user_balance",
    name: "user_balance",
    component: UserProfileSaldo,
    meta: {
      searchData: "userBalance",
      visible: false,
      label: "User Balance",
    },
  },
  {
    path: "/user_profile_edit",
    name: "user_profile_edit",
    component: UserProfileEdit,
    meta: {
      searchData: "userProfileEdit",
      visible: false,
      label: "User Profile Edit",
    },
  },
  {
    path: "/trackings/404",
    name: "erro_404",
    component: Erro404,
    meta: {
      visible: false,
      label: "404",
    },
  },
  {
    path: "/reset_password",
    name: "passwordNew",
    component: PasswordNew,
    meta: {
      searchData: "resetPassword",
      visible: false,
      label: "Password New",
    },
  },
  {
    path: "#trackings",
    name: "trackings",
    component: TrackingsIndex,
    meta: {
      visible: false,
      label: "Trackings",
    },
    children: [
      {
        path: "/trackings",
        name: "track_my_order",
        component: TrackMyOrder,
        meta: {
          searchData: "trackOrders",
          visible: false,
          label: "View",
        },
      },
      {
        path: "/trackings/:reference",
        name: "tracking_view",
        component: TrackingsView,
        props: true,
        meta: {
          visible: false,
          label: "View",
        },
      },
    ],
  },
  {
    path: "#quotes",
    name: "quotes",
    component: QuotesIndex,
    meta: {
      visible: true,
      heading: "solutions",
      label: "Quotes",
      icon: "clipboard",
    },
    children: [
      {
        path: "/quotes/new",
        name: "quotes_new",
        component: QuotesNew,
        meta: {
          searchData: "newQuote",
          visible: true,
          label: "New",
        },
      },
    ],
  },
  {
    path: "#orders",
    name: "orders",
    component: OrdersIndex,
    meta: {
      visible: true,
      heading: "solutions",
      label: "Orders",
      icon: "shopping-bag",
    },
    children: [
      {
        path: "/orders",
        name: "orders_list",
        component: OrdersList,
        meta: {
          searchData: "ordersList",
          visible: true,
          label: "OrdersManagement",
        },
      },
      {
        path: "/orders/import",
        name: "orders_import",
        component: OrdersImport,
        meta: {
          searchData: "orderImport",
          visible: true,
          label: "Import",
        },
      },
      {
        path: "/orders/import_response",
        name: "orders_response",
        component: ImportResponse,
        meta: {
          visible: false,
        },
      },
      /* {
        path: "/orders/create",
        name: "orders_create",
        component: OrdersCreate,
        props: true,
        meta: {
          searchData: "orderCreateNew",
          visible: true,
          label: "Create",
        },
      }, */
      {
        path: "/orders/ordercreate",
        name: "create_order",
        component: OrderCreate,
        props: true,
        meta: {
          searchData: "orderCreateNew",
          visible: true,
          label: "Create",
        },
      },
      /* {
        path: '/orders/:id/edit',
        name: 'orders_edit',
        component: OrdersEdit,
        meta: {
          visible: false,
          label: 'Edit'
        }
      }, */
      {
        path: "/orders/rejected",
        name: "orders_rejected",
        component: OrdersRejected,
        meta: {
          visible: true,
          label: "Rejected",
        },
      },
      {
        path: "/orders/volume_reverses",
        name: "volume_reverses",
        component: VolumeReverse,
        meta: {
          searchData: "volumeReverseList",
          visible: true,
          label: "VolumeReverse",
        },
      },
      {
        path: "/orders/volume_reverses/:id",
        name: "volume_reverses_view",
        component: VolumeReverseView,
        meta: {
          visible: false,
          label: "Volume Reverse view",
        },
      },
      {
        path: "/orders/pickup-dropoff",
        name: "pickup_dropoff",
        component: PickupDropoff,
        props: true,
        meta: {
          searchData: "orderPickupDropoff",
          visible: true,
          label: "PickupDropoff",
        },
      },
      {
        path: "/orders/tracking-alert-logs",
        name: "tracking_logs",
        component: DutiesAndTaxes,
        props: true,
        meta: {
          visible: true,
          label: "DutiesAndTaxes",
        },
      },
      {
        path: "/tracking-alert-logs/:id",
        name: "tracking_log",
        component: TrackingLogView,
        meta: {
          visible: false,
          label: "TrackingLogView",
        },
      },
      {
        path: "/amazon-import-files",
        name: "amazon_files",
        component: AmazonFiles,
        meta: {
          visible: false,
          label: "Amazon previous",
        },
      },
      {
        path: "/orders/amazon-import",
        name: "amazon_imported_edit",
        component: AmazonImport,
        meta: {
          visible: false,
          label: "Amazon",
        },
      },
      {
        path: "/orders/fiscal_payments",
        name: "fiscal_payments",
        component: FiscalPayments,
        meta: {
          searchData: "fiscalPayments",
          visible: true,
          label: "FiscalPayments",
        },
      },
      {
        path: "/orders/:id",
        name: "orders_view",
        component: OrdersView,
        meta: {
          visible: false,
          label: "Edit",
        },
      },
    ],
  },

  {
    path: "#overpacks",
    name: "overpacks",
    component: OverpacksIndex,
    meta: {
      visible: true,
      heading: "solutions",
      label: "Overpacks",
      icon: "package",
    },
    children: [
      {
        path: "/overpacks",
        name: "overpacks_list",
        component: Overpacks,
        meta: {
          searchData: "overpacksList",
          visible: true,
          label: "OverpacksManagement",
        },
      },
      {
        path: "/overpacks/:id",
        name: "overpacks_view",
        component: OverpacksView,
        meta: {
          visible: false,
          label: "Show",
        },
      },
    ],
  },
  {
    path: "#notifications",
    name: "notifications",
    component: NotificationIndex,
    meta: {
      visible: true,
      heading: "preferences",
      label: "Notifications",
      icon: "message-circle",
    },
    children: [
      {
        path: "/notifications/group_configuration",
        name: "GroupsConfiguration",
        component: GroupsConfiguration,
        meta: {
          searchData: "notificationsConfig",
          visible: true,
          label: "RulesConfiguration",
        },
      },
      {
        path: "/notifications/group_configuration/create",
        name: "CreateGroupConfigurations",
        component: CreateGroupConfiguration,
        meta: {
          searchData: "notificationsCreate",
          visible: false,
          label: "Create group configuration",
        },
      },
      {
        path: "/notifications/group_configuration/edit/:id",
        name: "GroupConfigurationEdit",
        component: GroupConfigurationEdit,
        meta: {
          visible: false,
          label: "Edit group configuration",
        },
      },
      {
        path: "/notifications/group_configuration/:id",
        name: "GroupConfigurationView",
        component: GroupConfigurationView,
        meta: {
          visible: false,
          label: "Group view",
        },
      },
      {
        path: "/notifications/settings",
        name: "TrackingNotifcationSettings",
        component: NotifcationSettings,
        meta: {
          searchData: "notificationsSettings",
          visible: true,
          label: "NotificationSettings",
        },
      },
    ],
  },
  {
    path: "#settings",
    name: "settings",
    component: SettingsIndex,
    meta: {
      visible: true,
      heading: "preferences",
      label: "Settings",
      icon: "settings",
    },
    children: [
      {
        path: "/settings/quickstart",
        name: "QuickStart",
        component: QuickStart,
        meta: {
          searchData: "settingsQuickstart",
          visible: false,
          label: "QuickStart",
        },
      },
      {
        path: "/settings/shipping_rules",
        name: "ShippingRules",
        component: SettingsShippingRules,
        meta: {
          searchData: "settingsShippingRules",
          visible: true,
          label: "ShippingRules",
        },
      },
      {
        path: "/settings/advanced",
        name: "Advanced",
        component: Advanced,
        meta: {
          visible: true,
          label: "Advanced",
        },
      },
      {
        path: "/settings/boxes-products",
        name: "BoxesProducts",
        component: BoxesProducts,
        meta: {
          searchData: "boxesProductsList",
          visible: true,
          label: "BoxesProducts",
        },
      },
      {
        path: "/settings/accessibility",
        name: "Accessibility",
        component: Accessibility,
        meta: {
          searchData: "settingsAccessibility",
          visible: true,
          label: "Accessibility",
        },
      },
      {
        path: "/settings/printing",
        name: "Printing",
        component: Printing,
        meta: {
          searchData: "settingsPrinting",
          visible: true,
          label: "Printing",
        },
      },
      {
        path: "/settings/sales-channels",
        name: "SalesChannelsSettings",
        component: SalesChannelsSettings,
        meta: {
          searchData: "settingsSalesChannels",
          visible: true,
          label: "SalesChannels",
        },
      },
      {
        path: "/products/import/:id",
        name: "log_product_import",
        component: ProductsLogImport,
        props: true,
        meta: {
          visible: false,
          label: "Import Log",
        },
      },
      {
        path: "/settings/customers",
        name: "customers",
        component: SettingsCustomers,
        meta: {
          searchData: "settingsCustomers",
          visible: true,
          label: "Customers",
        },
      },
      {
        path: "/settings/documents",
        name: "Documents",
        component: Documents,
        meta: {
          searchData: "settingsDocuments",
          visible: true,
          label: "Documents",
        },
      },
      {
        path: "/settings/insurance",
        name: "Insurance",
        component: Insurance,
        meta: {
          searchData: "settingsInsurance",
          visible: true,
          label: "Insurance",
        },
      },
      {
        path: "/settings/tracking",
        name: "TrackingPageSettings",
        component: TrackingPageSettings,
        meta: {
          searchData: "settingsTracking",
          visible: true,
          label: "TrackingPage",
        },
      },
    ],
  },
  {
    path: "#account",
    name: "account",
    component: AccountIndex,
    meta: {
      visible: true,
      heading: "preferences",
      label: "Account",
      icon: "user",
    },
    children: [
      {
        path: "/accounts/contracts",
        name: "contracts",
        component: Contracts,
        meta: {
          searchData: "contracts",
          visible: true,
          label: "Contracts",
        },
      },
      {
        path: "/user_profile",
        name: "user_profile",
        component: UserProfile,
        meta: {
          searchData: "userProfile",
          visible: true,
          label: "UserProfile",
        },
      },
      {
        path: "/company",
        name: "company",
        component: Company,
        meta: {
          searchData: "company",
          visible: true,
          label: "Company",
        },
      },
      {
        path: "/notifications/notifications_list",
        name: "NotificationsList",
        component: NotificationsList,
        meta: {
          searchData: "notificationsList",
          visible: true,
          label: "NotificationsList",
        },
      },
      {
        path: "/users/create",
        name: "users_create",
        component: UsersCreate,
        meta: {
          searchData: "usersCreateNew",
          visible: false,
          label: "Create",
        },
      },
      {
        path: "/users/:id",
        name: "users_view",
        component: UsersView,
        meta: {
          visible: false,
          label: "View",
        },
      },
      {
        path: "/users/:id/edit",
        name: "users_edit",
        component: UsersEdit,
        meta: {
          visible: false,
          label: "View",
        },
      },
      {
        path: "/account/users",
        name: "users",
        component: Users,
        meta: {
          searchData: "usersList",
          visible: true,
          label: "Users",
        },
      },
    ],
  },
  {
    path: "#integrations",
    name: "integrations",
    component: IntegrationsIndex,
    meta: {
      visible: true,
      heading: "connect",
      label: "Integrations",
      icon: "share-2",
    },
    children: [
      {
        path: "/integrations/couriers",
        name: "courier_list",
        component: CouriersList,
        meta: {
          visible: true,
          label: "CouriersList",
        },
      },
      {
        path: "/integrations/channels",
        name: "shannels_list",
        component: ChannelsList,
        meta: {
          visible: true,
          label: "ChannelsList",
        },
      },
      {
        path: "/integrations/price_tables",
        name: "price_tables",
        component: PriceTables,
        meta: {
          visible: true,
          label: "PriceTablesDeliveryTime",
        },
      },
    ],
  },
  /* ***doc onboard
  {
    path: '/onboard',
    name: 'onboard',
    component: Onboard,
    meta: {
      visible: false,
      label: 'Onboard',
      icon: 'user'
    }
  }, 
  */
  {
    path: "/archives",
    name: "download",
    component: Downloads,
    meta: {
      visible: true,
      heading: "solutions",
      label: "Archives",
      icon: "download",
    },
  },
  {
    path: "/current-version",
    name: "Current Version",
    component: CurrentVersion,
    meta: {
      visible: false,
    },
  },
];
/* Talvez utilizar caso a fun�ao de remover rotas gere algum bug
function dinamicRoutes() {
  return  { 
    overpacks: {
      path: '#overpacks',
      name: 'overpacks',
      component: OverpacksIndex,
      meta: {
        visible: true,
        label: 'Overpacks',
        icon: 'box'
      },
      children: [
        {
          path: '/overpacks',
          name: 'overpacks_list',
          component: OverpacksList,
          meta: {
            visible: true,
            label: 'OverpacksManagement',
          }
        },
        {
          path: '/overpacks/:id',
          name: 'overpacks_view',
          component: OverpacksView,
          meta: {
            visible: false,
            label: 'Show'
          }
        }
      ]
    },
    integrations: {
      path: '#integrations',
      name: 'integrations',
      component: IntegrationsIndex,
      meta: {
        visible: true,
        label: 'Integrations',
        icon: 'plug'
      },
      children: [
        {
          path: '/integrations/couriers',
          name: 'courier_list',
          component: CouriersList,
          meta: {
            visible: true,
            label: 'CouriersList',
          }
        },
        {
          path: '/integrations/channels',
          name: 'shannels_list',
          component: ChannelsList,
          meta: {
            visible: true,
            label: 'ChannelsList',
          }
        }
      ]
    }
  }
}

(function() {

  const overpacks = dinamicRoutes().overpacks
  if(userService.getUserAttribute('can_overpack')) { routes.splice(14, 0, overpacks) }

  const integrations = dinamicRoutes().integrations
  if(helpers.getPlatform() !== 'total') { routes.splice(16, 0, integrations) }

})();
*/

function removeRouteGroup(routeGroupName) {
  const indexToRemove = routes.findIndex(
    (route) => route.name === routeGroupName
  );
  if (indexToRemove > -1) {
    routes.splice(indexToRemove, 1);
  }
}
/* usado para remover link dentro de um grupo exemplo de uso: removeRouteByName('settings', 'ShippingRules');
function removeRouteByName(routeGroupName, routeName) {
  const routeGroup = routes.find(route => route.name === routeGroupName);
  if(routeGroup) {
    const indexToRemove = routeGroup.children.findIndex(route => route.name === routeName);
    if(indexToRemove > -1) {
      routeGroup.children.splice( indexToRemove, 1);
    }
  }
} 
*/

//remove routes
(function () {
  if (!userService.getUserAttribute("can_overpack")) {
    removeRouteGroup("overpacks");
  }

  //TOTAL
  if (helpers.getPlatform() === "total") {
    removeRouteGroup("integrations");
    removeRouteGroup("notifications");
  }
  //Tiendamia trackings
  const path = window.location.pathname;
  const noUser =
    path.includes("reset_password") ||
    path.includes("-login") ||
    path.includes("trackings"); //add paths without user

  if (helpers.getPlatform() === "tiendamia" && !noUser) {
    usersService.getCurrentUser().then((response) => {
      groupService.getGroup(response.data.data.group_id).then((response) => {
        const user_group = response.data.data.name.toLowerCase();
        if (
          user_group === "seller-viewer" ||
          user_group === "seller-operation"
        ) {
          removeRouteGroup("integrations");
          removeRouteGroup("notifications");
          removeRouteGroup("settings");
        }
      });
    });
  }
})();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.beforeEach((to, from, next) => {
  const prefix = helpers.getPlatform("prefix");
  const platform = helpers.getPlatform();
  const url = window.location.host;

  if (
    url.toLowerCase().endsWith("trackmyorder.io") &&
    to.name !== "home" &&
    to.name !== "track_my_order" &&
    to.name !== "tracking_view" &&
    to.name !== "erro_404"
  ) {
    next("/trackings");
  } else if (
    !localStorage[`${platform}-user`] &&
    to.name !== "home" &&
    to.name !== "login" &&
    to.name !== "total_login" &&
    to.name !== "asia_login" &&
    to.name !== "ecross_login" &&
    to.name !== "anjun_login" &&
    to.name !== "passwordNew" &&
    to.name !== "tracking_view" &&
    to.name !== "track_my_order" &&
    to.name !== "erro_404"
  ) {
    next(`/${prefix}-login`);
  } else {
    next();
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar fixed-top navbar-light bg-header dark",class:_vm.showSidenav ? 'splitscreen-topBar' : 'fullScreen-topBar'},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('NbSearchAheadInput',{staticClass:"global-search-input",attrs:{"items":_vm.routes,"serializer":_vm.serializer,"id":"top-search","name":"","placeholder":"What are you looking for today?","error":_vm.errors.globalSearch,"size":"sm"},on:{"selected":function($event){return _vm.goTo($event)}},model:{value:(_vm.globalSearch),callback:function ($$v) {_vm.globalSearch=$$v},expression:"globalSearch"}}),(_vm.topBarOption === 1)?_c('div',[(typeof _vm.isSaving === 'boolean' && !_vm.isSaving)?_c('div',{staticClass:"mx-3 body-2-semibold text-gray-60 anim-fade-one"},[_c('NbIcon',{attrs:{"icon":"save","attributes":{
              class: 'mr-1 mb-1',
              width: '1rem',
              height: '1rem',
            }}}),_vm._v(" "+_vm._s(_vm.$t("components.baseTemplate.topBar.lastEdit"))+" "),_c('span',{staticClass:"body-2-bold text-gray-60"},[_vm._v(_vm._s(_vm.$t("components.baseTemplate.topBar.saved")))])],1):_vm._e(),(typeof _vm.isSaving === 'boolean' && _vm.isSaving)?_c('div',{staticClass:"mx-3 body-2-semibold text-gray-60 anim-fade-two"},[_c('NbIcon',{attrs:{"icon":"loader","attributes":{
              class: 'mr-1 mb-1 spin',
              width: '1rem',
              height: '1rem',
            }}}),_vm._v(" "+_vm._s(_vm.$t("components.baseTemplate.topBar.saving"))+"... ")],1):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"user-menu d-inline-flex align-items-center"},[_c('NbOption',{staticClass:"mx-1",attrs:{"id":"user-options","options":_vm.buttonOptions,"optionsWidth":"200","expandTo":"right","size":"sm"},on:{"input":function($event){return _vm.userOptionClicked($event)}}},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('router-link',{staticClass:"btn-balance",attrs:{"to":"/user_balance"}},[_vm._v(" "+_vm._s(_vm.$t("balance"))+": "),_c('span',{attrs:{"data-balance":_vm.balance > 0 ? 'credit' : 'debit'}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.balance))+" ")])]),_c('NbNotificationBtn',{staticClass:"mx-1",attrs:{"id":"notification-options","notifications":_vm.sellerNotifications,"options":_vm.buttonOptions,"optionsWidth":"345","expandTo":"right","size":"sm"},on:{"input":function($event){return _vm.userOptionClicked($event)}}},[_c('NbIcon',{attrs:{"icon":"bell"}})],1),_c('LangButton',{attrs:{"ml":"2","user":_vm.user},on:{"loadSearchData":function($event){return _vm.loadSearchData()}}})],1)]),_c('ConditionsService')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }